<template>
  <div class="pt-20 pb-28">
    <div class="text-primary text-3xl text-center font-bold">
      Choisissez le forfait qui convient
    </div>
    <div class="text-primary text-3xl text-center font-bold pt-3">
      le mieux à vos besoins
    </div>
    <div class="text-center text-sm pt-4 pb-10 text-black">
      Payez au mois ou à l’année et annulez à tout moment.
    </div>
    <div class="flex flex-wrap justify-center">
      <div class="lds-ring" id="md" v-if="cardData.length == 0">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div
        class="rounded-lg bg-primary bg-white self-end tarif-pro blue-bg"
        v-for="p in cardData"
        :key="p.id"
      >
        <div class="flex justify-between">
          <button class="btn-pro">
            <label class="Suggested white-text">{{ p.name }}</label>
          </button>
          <div class="flex">
            <span class="abo-mensual-annuel white-text">Abo {{ p.type }}</span>
          </div>
        </div>
        <div class="mt-8">
          <div
            v-if="p.type == 'Annuel'"
            class="flex justify-between text-primary mr-8"
          >
            <div>
              <p class="font-bold white-text" style="font-size: 22px">Annuel</p>
              <p class="text-primary white-text">Abonnement annuel</p>
            </div>
            <div class="relative w-1/2">
              <p class="flex justify-end text-primary" style="color: #fff">
                Prix HT par mois
              </p>
              <p
                class="text-tertiary flex justify-end font-bold"
                style="font-size: 26px"
              >
                {{ p.prices.annual.amount }} € / mois 
              </p>
              <p
                class="text-tertiary font-bold absolute lg:right-0"
                style="font-size: 13px"
              >
              </p>
            </div>
          </div>

          <div
            v-if="p.type == 'Mensuel'"
            class="flex justify-between text-primary mr-8"
          >
            <div>
              <p class="font-bold white-text" style="font-size: 22px">
                Mensuel
              </p>
              <p class="text-primary white-text">Abonnement mensuel</p>
            </div>
            <div class="relative w-1/2">
              <p
                class="flex justify-end text-primary white-text"
                style="color: #fff"
              >
                Prix HT par mois
              </p>
              <p
                class="text-tertiary flex justify-end font-bold"
                style="font-size: 26px"
              >
                {{ p.prices.month.amount }} € / mois 
              </p>
            </div>
          </div>

          <div class="mt-10" style="min-height: 255px">
            <SectionTarifPro
              v-for="(card, index) in p.fonctionnalities"
              :key="index"
              :icon="require('../assets/checkmark.svg')"
              :content="card"
              :courte="true"
            />
          </div>
              <a
                target="_blank"
                href="https://exval.app/register"
                v-if="p.type == 'Annuel'"
              >
                <span class="CTA-Big-primary m-auto border-white">
                  <label class="label text-white m-auto">Je m'abonne</label>
                </span>
              </a>

              <a
                target="_blank"
                href="https://exval.app/register"
                v-if="p.type == 'Mensuel'"
              >
                <span class="CTA-Big-primary m-auto border-white">
                  <label class="label text-white m-auto">Je m'abonne</label>
                </span>
              </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
//import { cardData } from "../components/b2b8/data";
import SectionTarifPro from "../components/b2b2/SectionTarifPro.vue";
// import Swipper from "../components/b2b2/Swipper";

import axios from "axios";
export default defineComponent({
  name: "B2b8View",
  components: {
    SectionTarifPro,
    // Swipper,
    //B2b8Card: require("../components/b2b8/Card.vue").default,
  },
  data() {
    return {
      cardData: [],
    };
  },
  mounted() {
    const self = this;
    axios({
      method: "GET",
      url: "https://api.exval.fr/api/v1/static-page/plans",
    })
      .then(function (response) {
        const d = response.data.datas;
        self.cardData = d.map(function (item) {
          let i = item;
          i["swipper"] = false;
          return i;
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  methods: {
    swippe(id) {
      const p = this.cardData.map(function (item) {
        if (item.id == id) {
          item.swipper = !item.swipper;
        }
        return item;
      });
      this.cardData = [...p];
    },
  },
});
</script>
<style scoped>
.CTA-Big-primary {
  width: 129.8px;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 116px;
  border-radius: 4px;
  background-color: #363660;
}

.label {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}
.tarif-court {
  width: 460px;
  height: 600px;
  padding: 55px 40px 33px;
  background-color: #363660;
}
.tarif-pro {
  width: 480px;
  height: 680px;
  padding: 25px 12.7px 24px 40px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 0 10px;
}
.btn-pro {
  height: auto;
  padding: 2.1px 10.2px 1.1px 7.1px;
  border-radius: 3px;
  background-color: #e94a73;
}
.Suggested {
  width: 97.4px;
  height: 29.8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.14px;
  text-align: left;
  color: #ffffff;
}
.abo-mensual-annuel {
  height: 20px;
  margin: 5px 10px 5px 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #363660;
}
.title1 {
  height: 40px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: 3px;
  text-align: center;
  color: #e94a73;
}
.title-objectif {
  width: 463px;
  height: 96px;
  margin: 0 0 73px 1px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}

.button-cookie {
  height: 32px;
  gap: 8px;
  padding: NaNpx;
  border-radius: 4px;
  border: solid 1px #363660;
}
.opacity {
  opacity: 0.6;
  z-index: 2;
}
.blue-bg {
  background-color: #363660;
}
.white-text {
  color: #fff !important;
}
.border-white {
  border: 1px solid #fff;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 8px;
  border: 2px solid #383366;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #383366 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
